import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import blur from "../../../imgs/blurs/blurFooter.jpg";
import logoWhite from "../../../imgs/logo_white.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageContainer from "../PageContainer";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigation = [
    { title: "Agency", path: "/agency" },
    { title: "Services", path: "/services" },
    { title: "Technologies", path: "/technologies" },
    { title: "Contact", path: "/contact" },
    { title: "Blog", path: "/blog" },
  ];

  const socials = [
    //
    // { title: "Twitter", path: "https://www.twitter.com" },
    { title: "LinkedIn", path: "https://www.linkedin.com/company/digitup-company/" },
    { title: "Instagram", path: "https://www.instagram.com/digitupcompany/" },
    { title: "Facebook", path: "https://www.facebook.com/people/Digitup-Company/100080632615027/" },


  ];
  function handleClickEmail(email) {
    window.location.href = `mailto:${email}`;
  }
  function handleClickPhone(phoneNumber) {
    window.location.href = `tel:${phoneNumber}`;
  }

  return (
    <>
      <Box component="section">
        <Box sx={{ overflow: "hidden", position: "relative", backgroundColor: "black", padding: "3rem 0rem 0rem 0rem", display: "flex", justifyContent: "center" }}>
          <img src={blur} style={{ position: "absolute", bottom: "0%" }} />
          <Container maxWidth="xl">
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", textAlign: "center" }}>
              <Grid zIndex="99" container>
                <Grid item lg={3}>
                  <Box sx={{ padding: "2rem 1rem 2rem 0rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Box onClick={() => navigate("/")} sx={{ width: "6rem", cursor: "pointer" }} textAlign="left">
                      <img src={logoWhite} alt="digitup logo" />
                    </Box>
                    <Box textAlign="left" sx={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
                      <Typography sx={{ wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                        {t("footer.slogan")}
                      </Typography>

                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={3}>
                  <Box sx={{ padding: "2rem 1rem 2rem 0rem", display: "flex", flexDirection: "column", gap: "1rem", textAlign: "left  " }}>
                    {navigation.map((n, index) => (
                      <Box key={index} className="link">
                        <Link style={{ textDecoration: "none" }} key={index} to={n.path}>
                          <Typography sx={{ wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                            {t(`${n.title}`)}
                          </Typography>
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                <Grid item lg={3}>
                  <Box sx={{ padding: "2rem 1rem 2rem 0rem", display: "flex", flexDirection: "column", gap: "1rem", textAlign: "left" }}>
                    <Typography sx={{ wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                      Informations
                    </Typography>
                    <Typography sx={{ color: "gray", wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                      10, rue de Penthièvre, 75008 Paris, France
                    </Typography>
                    <Typography onClick={() => handleClickPhone("+33 7 58 90 96 81")} sx={{ color: "gray", wordBreak: "break-word", cursor: "pointer" }} variant="h7" color="white" fontFamily={"Poppins"}>
                      +33 7 58 90 96 81
                    </Typography>
                    <Typography onClick={() => handleClickEmail("hello@digitupcompany.com")} sx={{ color: "gray", cursor: "pointer" }} variant="h7" color="white" fontFamily={"Poppins"}>
                      hello@digitupcompany.com
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={3}>
                  <Box sx={{ padding: "2rem 1rem 2rem 0rem", display: "flex", flexDirection: "column", gap: "1rem", textAlign: "left" }}>
                    <Typography sx={{ wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                      Socials
                    </Typography>
                    {socials.map((s, index) => (
                      <Box key={index} className="link">
                        <a target="_blank" style={{ textDecoration: "none" }} key={index} href={s.path}>
                          <Typography sx={{ color: "gray", wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                            {t(`${s.title}`)}
                          </Typography>
                        </a>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: "5rem", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "2rem" }}>
              <Typography sx={{ zIndex: "1", wordBreak: "break-word" }} variant="h7" color="white" fontFamily={"Poppins"}>
                {t("footer.copyright")}
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default Footer;

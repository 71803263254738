import React from "react";
import { Box, Typography } from "@mui/material";
import MiniTitle from "../../components/ui/MiniTitle.js/index.js";
import { useTranslation } from "react-i18next";
import canvasPlaceHolder from "../../imgs/DC2.png";

function HeroSection() {
  const { t } = useTranslation();

  return (
    <>
      <Box component="section" >
        <Box >
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: { md: "center" }, justifyContent: { xs: "center" } }}>
            <Box sx={{ flex: "1" }}>
              <MiniTitle name="Agency" />
              <Typography className="digitup gradient-text" component="h1" variant="h1" fontFamily={"ITC"}>
                {t("agencyPage.hero.digitup")}
              </Typography>
              <Typography className="digitup gradient-text" component="h1" variant="h1" fontFamily={"ITC"}>
                {t("agencyPage.hero.company")}
              </Typography>
            </Box>
            <Box className="canvas-logo" sx={{ flex: "1", width: "100%", padding: "2rem 0rem", display: "flex", justifyContent: "center" }}>
              <img style={{ maxWidth: "100%", height: "auto" }} src={canvasPlaceHolder} alt="" />
            </Box>
          </Box>
          <Box className="about-digitup" sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}></Box>
        </Box>

        <Box>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: { xs: "3rem", md: "5rem" }, justifyContent: "space-between", marginBottom: "2rem" }}>
            <Box sx={{ maxWidth: "850px" }}>
              <Typography align="justify" variant="h6" fontFamily={"ITC"}>
                {t("agencyPage.hero.paragraph1")}
              </Typography>
            </Box>
            <Box sx={{ maxWidth: "850px" }}>
              <Typography align="justify" variant="h6" fontFamily={"ITC"}>
                {t("agencyPage.hero.paragraph2")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default HeroSection;

import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import MiniTitle from "../../components/ui/MiniTitle.js/index.js";
import PageContainer from "../../components/ui/PageContainer";
import blurServices from "../../imgs/blurs/blurServices.jpg";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { ReactComponent as IaIcon } from "../../imgs/icons/ia.svg"; // Exemple de chemin vers l'icône
import { ReactComponent as DevIcon } from "../../imgs/icons/dev.svg";
import { ReactComponent as UiUxIcon } from "../../imgs/icons/ui_ux.svg";
import { ReactComponent as HostingIcon } from "../../imgs/icons/hosting.svg";
import { ReactComponent as NetworksIcon } from "../../imgs/icons/network.svg";
import { ReactComponent as SecurityIcon } from "../../imgs/icons/security.svg";
import { ReactComponent as TmaIcon } from "../../imgs/icons/tma.svg";
import { ReactComponent as ConsultingIcon } from "../../imgs/icons/consulting.svg";
import { ReactComponent as AuditIcon } from "../../imgs/icons/audit.svg";
import { useNavigate } from "react-router-dom";
import WhyChooseUsSection from "./WhyChooseUsSection";

const Card = styled(Box)({
  background: "rgba(50, 50, 50, 0.438)",
  borderRadius: "25px",
  border: "1px solid rgb(82, 82, 82)",
  position: "relative",
  width: "100%",
  height: "300px", // Taille uniforme pour chaque carte
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem",
  textAlign: "center",
});

const IconBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "50%",
  opacity: 0.15,
});

const ServiceCard = ({ title, icon: Icon, description }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleContactClick = () => {
    navigate("/contact");
  };

  return (
      <Card>
        <IconBox>
          <Icon style={{ width: "100%", height: "100%", fill: "#007bff" }} /> {/* Couleur unique pour l'icône */}
        </IconBox>
        <Typography color="white" variant="h4" sx={{ zIndex: 99, textAlign: "center", marginBottom: "1rem" }}>
          {title}
        </Typography>
        <Typography variant="h6" fontFamily={"ITC"} color="white" mb={2}>
          {description}
        </Typography>
      </Card>
  );
};

function ServicesSection() {
  const { t } = useTranslation();

  const services = [
    { id: "ia", icon: IaIcon, description: t("servicesPage.services.ia__description") },
    { id: "dev", icon: DevIcon, description: t("servicesPage.services.dev__description") },
    { id: "ui_ux", icon: UiUxIcon, description: t("servicesPage.services.ui_ux__description") },
    { id: "hosting", icon: HostingIcon, description: t("servicesPage.services.hosting__description") },
    { id: "networks", icon: NetworksIcon, description: t("servicesPage.services.networks__description") },
    { id: "security", icon: SecurityIcon, description: t("servicesPage.services.security__description") },
    { id: "tma", icon: TmaIcon, description: t("servicesPage.services.tma__description") },
    { id: "consulting", icon: ConsultingIcon, description: t("servicesPage.services.consulting__description") },
    { id: "audit", icon: AuditIcon, description: t("servicesPage.services.audit__description") }
  ];

  return (
      <>
        <div>
          <Box component="section" sx={{ zIndex: "0", padding: "3rem 0rem", position: "relative", overflow: "hidden" }}>
            <Box sx={{ zIndex: "0", position: "absolute", top: { xs: "0%", md: "10%" } }}>
              <img src={blurServices} alt="Background" />
            </Box>
            <Container maxWidth="xl">
              <Box sx={{ zIndex: "99", display: "flex", flexDirection: "column" }}>
                <MiniTitle name={t("servicesPage.title")} />
                <Typography color="white" component="h2" sx={{ zIndex: "1", paddingBottom: "1rem" }} variant="h2" fontFamily={"ITC"}>
                  {t("servicesPage.subtitle")}
                </Typography>
                <Typography color="white" component="p" sx={{ zIndex: "1", paddingBottom: "4rem" }} variant="body1" fontFamily={"ITC"}>
                  {t("servicesPage.description")}
                </Typography>
              </Box>

              <Grid container className="values" spacing={4}>
                {services.map((service) => (
                    <Grid item xs={12} sm={6} md={4} key={service.id}>
                      <ServiceCard
                          title={t(`servicesPage.services.${service.id}`)}
                          icon={service.icon}
                          description={service.description}
                      />
                    </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </div>
        <WhyChooseUsSection />
      </>
  );
}

export default ServicesSection;

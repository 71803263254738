import React from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { ReactComponent as CheckIcon } from "../../imgs/icons/check.svg"; // Exemple de chemin vers l'icône

const WhyChooseUsSection = () => {
    const { t } = useTranslation();

    const reasons = [
        { id: "expertise", description: t("servicesPage.whyChooseUs.expertise") },
        { id: "innovation", description: t("servicesPage.whyChooseUs.innovation") },
        { id: "customerSatisfaction", description: t("servicesPage.whyChooseUs.customerSatisfaction") },
        { id: "continuousSupport", description: t("servicesPage.whyChooseUs.continuousSupport") }
    ];

    return (
        <Box component="section" sx={{ padding: "3rem 0rem", textAlign: "center" }}>
            <Container maxWidth="xl">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" sx={{ marginBottom: "2rem", fontFamily: "ITC", color: "white" }}>
                            {t("servicesPage.whyChooseUs.title")}
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: "2rem", fontFamily: "ITC", color: "white" }}>
                            {t("servicesPage.whyChooseUs.description")}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://calendly.com/techdigitup/30min"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ marginTop: "1rem", width: "100%" }}
                        >
                            {t("servicesPage.cta")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            {reasons.map((reason) => (
                                <Box key={reason.id} sx={{ display: "flex", alignItems: "flex-start", marginBottom: "1rem" }}>
                                    <CheckIcon style={{ width: "24px", height: "24px", fill: "#007bff", marginRight: "1rem" }} /> {/* Couleur unique pour l'icône */}
                                    <Typography color="white" variant="body1" fontFamily={"ITC"} sx={{ textAlign: "left" }}>
                                        {reason.description}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default WhyChooseUsSection;
